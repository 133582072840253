import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, resolveComponent as _resolveComponent, withCtx as _withCtx, createTextVNode as _createTextVNode, createBlock as _createBlock } from "vue"
import _imports_0 from '@/assets/img/went.png'
import _imports_1 from '@/assets/img/common_own1.png'
import _imports_2 from '@/assets/img/common_own2.png'
import _imports_3 from '@/assets/img/reward.png'
import _imports_4 from '@/assets/img/refresh.png'
import _imports_5 from '@/assets/img/down.png'
import _imports_6 from '@/assets/img/went_senior.png'
import _imports_7 from '@/assets/img/own_beijing1.png'
import _imports_8 from '@/assets/img/own_beijing2.png'
import _imports_9 from '@/assets/img/reward_senior.png'
import _imports_10 from '@/assets/img/refresh_senior.png'


const _hoisted_1 = { class: "main" }
const _hoisted_2 = {
  key: 0,
  id: "mian_common",
  style: {"background-color":"#f6f6f8"},
  class: "mian_common"
}
const _hoisted_3 = { class: "user" }
const _hoisted_4 = { class: "left_user" }
const _hoisted_5 = { class: "pictrue" }
const _hoisted_6 = ["src"]
const _hoisted_7 = { class: "left_info" }
const _hoisted_8 = { class: "name" }
const _hoisted_9 = { class: "userId" }
const _hoisted_10 = {
  key: 0,
  class: "right_user"
}
const _hoisted_11 = { class: "timing" }
const _hoisted_12 = { key: 0 }
const _hoisted_13 = ["innerText"]
const _hoisted_14 = { class: "wallet" }
const _hoisted_15 = { style: {"display":"flex","justify-content":"space-between"} }
const _hoisted_16 = { class: "text" }
const _hoisted_17 = { class: "residue" }
const _hoisted_18 = { style: {"display":"flex","justify-content":"space-between","margin-top":"0.26rem"} }
const _hoisted_19 = { class: "amount" }
const _hoisted_20 = { style: {"height":"0.21rem","font-size":"0.16rem","font-family":"MiSans, MiSans","font-weight":"600","color":"#5eb15c","line-height":"0.21rem"} }
const _hoisted_21 = { style: {"height":"0.42rem","font-size":"0.32rem","font-family":"MiSans, MiSans","font-weight":"600","color":"#5eb15c","line-height":"0.42rem"} }
const _hoisted_22 = {
  key: 1,
  class: "go_wallet"
}
const _hoisted_23 = { class: "own" }
const _hoisted_24 = { class: "content" }
const _hoisted_25 = { class: "own_title" }
const _hoisted_26 = { class: "text" }
const _hoisted_27 = { class: "own_text" }
const _hoisted_28 = { class: "own_num" }
const _hoisted_29 = { class: "content" }
const _hoisted_30 = { class: "own_title" }
const _hoisted_31 = { class: "text" }
const _hoisted_32 = { class: "own_text" }
const _hoisted_33 = { class: "own_num" }
const _hoisted_34 = { class: "record" }
const _hoisted_35 = { class: "top" }
const _hoisted_36 = { class: "top_left" }
const _hoisted_37 = { class: "reward_title" }
const _hoisted_38 = { style: {"width":"100%"} }
const _hoisted_39 = { class: "lists" }
const _hoisted_40 = { class: "text" }
const _hoisted_41 = {
  key: 1,
  class: "mian_senior",
  style: {"background-color":"#f6f6f8"}
}
const _hoisted_42 = { class: "user" }
const _hoisted_43 = { class: "left_user" }
const _hoisted_44 = { class: "pictrue" }
const _hoisted_45 = ["src"]
const _hoisted_46 = { class: "left_info" }
const _hoisted_47 = { class: "name" }
const _hoisted_48 = { class: "userId" }
const _hoisted_49 = {
  key: 0,
  class: "right_user"
}
const _hoisted_50 = { class: "timing" }
const _hoisted_51 = { key: 0 }
const _hoisted_52 = ["innerText"]
const _hoisted_53 = { class: "wallet_senior" }
const _hoisted_54 = { style: {"display":"flex","justify-content":"space-between"} }
const _hoisted_55 = { class: "text" }
const _hoisted_56 = { class: "residue" }
const _hoisted_57 = { style: {"display":"flex","justify-content":"space-between","margin-top":"0.26rem"} }
const _hoisted_58 = { class: "amount" }
const _hoisted_59 = { style: {"height":"0.21rem","font-size":"0.16rem","font-family":"MiSans, MiSans","font-weight":"600","color":"#ed931e","line-height":"0.21rem"} }
const _hoisted_60 = { style: {"height":"0.42rem","font-size":"0.32rem","font-family":"MiSans, MiSans","font-weight":"600","color":"#ed931e","line-height":"0.42rem"} }
const _hoisted_61 = {
  key: 1,
  class: "go_wallet"
}
const _hoisted_62 = { class: "own_senior" }
const _hoisted_63 = { class: "content" }
const _hoisted_64 = { class: "own_title" }
const _hoisted_65 = { class: "text" }
const _hoisted_66 = { class: "own_text" }
const _hoisted_67 = { class: "own_num" }
const _hoisted_68 = { class: "content" }
const _hoisted_69 = { class: "own_title" }
const _hoisted_70 = { class: "text" }
const _hoisted_71 = { class: "own_text" }
const _hoisted_72 = { class: "own_num" }
const _hoisted_73 = { class: "record_senior" }
const _hoisted_74 = { class: "top" }
const _hoisted_75 = { class: "top_left" }
const _hoisted_76 = { class: "reward_title" }
const _hoisted_77 = { style: {"width":"100%"} }
const _hoisted_78 = { class: "lists" }
const _hoisted_79 = { class: "text" }

import { onMounted, reactive, ref } from 'vue';
import router from '@/router';
import { income, personal, in_wallet } from '@/api/fetcher';
import message from '@/utils/message';
import Top from '@/components/Top/top.vue';
import Tabbar from '@/components/Tabbar/tabbar.vue';
import { lang } from '@/lang';
import { IncomeItem, UserData } from '@/api/protocol';
import dataCenter from '@/dataCenter';

export default /*@__PURE__*/_defineComponent({
  __name: 'become_intelligent',
  setup(__props) {

let main = ref(true);

const wallet = () => {
  router.push('/wallet');
};

const imgs = {
  img1: require('@/assets/img/user.png'),
  img2: require('@/assets/img/senior_user.png'),
  img3: require('@/assets/img/timeout.png'),
};

const five = lang.v.five;
const four = lang.v.four;
const Samsung = lang.v.Samsung;
const two = lang.v.two;
const one = lang.v.one;
const nomoney = lang.v.nomoney;
const zhuanru = ref(true);

const getIncomeTypeName = (row: { type: number }) => {
  switch (row.type) {
  case 0:
    return lang.v.taskRewards;
  case 3:
    return lang.v.MovieRewards;
  case 4:
    return lang.v.starRewards;
  default:
    return '';
  }
};
const tixian = async () => {
  zhuanru.value = false;
  if (Number(userinfo.value.talentBalance) == 1) {
    message.error(nomoney);
  } else {
    try {
      await in_wallet({
        amount: userinfo.value.talentBalance,
        type: 1,
      });
      const res = await personal();
      if (res) {
        dataCenter.user.save(res);
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        userinfo.value = dataCenter.user.data;
        message.success(lang.v.success2);
      }
    } catch (error) {
      zhuanru.value = true;
    }
  }
  zhuanru.value = true;
};

const pages = reactive({
  page: 1,
  limit: 6,
});
const more = ref(true);
const no_more = ref(false);
// 刷新按钮
const imageStyle = ref({
  transform: 'rotate(0deg)', // 初始旋转角度为0度
  transition: 'transform 0.5s ease', // 添加过渡效果
});
const refresh = async () => {
  const currentRotation =
    parseInt(imageStyle.value.transform.slice(7), 10) || 0; // 获取当前旋转角度
  const rotationAngle = currentRotation + 360; // 计算下一次旋转角度
  // 更新图片的样式，应用旋转效果
  imageStyle.value.transform = `rotate(${rotationAngle}deg)`;
  lists.value = [];
  setTimeout(async () => {
    await income({
      page: '1',
      limit: String(pages.limit),
    }).then(async res => {
      const data = res;
      if (data && data.list && data.list.length) {
        more.value = false;
        if (data.list.length == 0) {
          no_more.value = true;
        }
        lists.value = [...data.list];
      } else {
        more.value = true;
        no_more.value = false;
      }
    });
  }, 500);
};

// 续费
const renew = () => {
  if (userinfo.value.talentCardType != null) {
    sessionStorage.setItem(
      'intelligent_id',
      JSON.stringify(userinfo.value.talentCardType)
    );
    router.push('/open_intelligent');
  } else {
    sessionStorage.setItem(
      'intelligent_id',
      JSON.stringify(userinfo.value.lastTalentCardType)
    );
    router.push('/open_intelligent');
  }
};

const daily = () => {
  router.push('/dailytasks');
};
const code_vip = () => {
  router.push('/redemption');
};

const lists = ref<IncomeItem[]>([]);
// 使用默认值确保解析的内容始终是一个对象
const userInfoString = dataCenter.user.data;
let parsedUserInfo: UserData = {} as UserData;

if (userInfoString) {
  try {
    parsedUserInfo = userInfoString;
  } catch (error) {
    console.error(error);
  }
}
const userinfo = ref<UserData>(parsedUserInfo);
const loading = ref(false);
const loadMore = async () => {
  loading.value = true;
  await income({
    page: String(pages.page + 1),
    limit: String(pages.limit),
  }).then(res => {
    loading.value = false;
    if (res) {
      lists.value = [...lists.value, ...res.list];
      pages.page++;
      more.value = true;
    } else {
      more.value = false;
    }
  });
};
const timeDifferenceInMilliseconds = ref();
const currentTime = new Date();
onMounted(async () => {
  await personal().then(res => {
    if (res) {
      dataCenter.user.data = res;
    }
  });
  try {
    const userInfoString = dataCenter.user.data;
    if (userInfoString) {
      userinfo.value = userInfoString;
    }
  } catch (e) {
    console.error('Error parsing userInfo from localStorage:', e);
  }
  if (userinfo.value.talentCardExpiredTime != null) {
    timeDifferenceInMilliseconds.value = Math.abs(
      Number(userinfo.value.talentCardExpiredTime) - Number(currentTime)
    );
  } else {
    timeDifferenceInMilliseconds.value = 0;
  }
  if (
    userinfo.value.talentCardType == 1 ||
    userinfo.value.lastTalentCardType == 1
  ) {
    main.value = true;
  }
  if (
    userinfo.value.talentCardType == 2 ||
    userinfo.value.lastTalentCardType == 2
  ) {
    main.value = false;
  }
  await income({
    page: '1',
    limit: String(pages.limit),
  }).then(res => {
    const data = res;
    if (data) {
      if (data?.list.length < pages.limit) {
        more.value = false;
        if (data.list.length == 0) {
          no_more.value = true;
        }
      } else {
        more.value = true;
        no_more.value = false;
      }
      lists.value = [...data.list];
    }
  });
});

return (_ctx: any,_cache: any) => {
  const _component_van_loading = _resolveComponent("van-loading")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_table = _resolveComponent("el-table")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(Top),
    _cache[14] || (_cache[14] = _createElementVNode("div", { class: "nav" }, null, -1)),
    (_unref(main))
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", {
            class: "info",
            style: _normalizeStyle({
          background:
            timeDifferenceInMilliseconds.value <= 0 ||
            userinfo.value.talentCardExpiredTime == null
              ? 'url(' + imgs.img3 + ')'
              : 'url(' + imgs.img1 + ')',
        })
          }, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("img", {
                    src: userinfo.value.avatar,
                    alt: ""
                  }, null, 8, _hoisted_6)
                ]),
                _createElementVNode("div", _hoisted_7, [
                  _createElementVNode("div", _hoisted_8, _toDisplayString(userinfo.value.nickname), 1),
                  _createElementVNode("div", _hoisted_9, "UID: " + _toDisplayString(userinfo.value.userId), 1)
                ])
              ]),
              (
              timeDifferenceInMilliseconds.value <= 0 ||
                userinfo.value.talentCardExpiredTime == null
                ? false
                : true
            )
                ? (_openBlock(), _createElementBlock("div", _hoisted_10, _toDisplayString(_unref(lang).v.period), 1))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", _hoisted_11, [
              (
              timeDifferenceInMilliseconds.value <= 0 ||
                userinfo.value.talentCardExpiredTime == null
                ? false
                : true
            )
                ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                    (userinfo.value.teamLevel == 0 ? false : true)
                      ? (_openBlock(), _createElementBlock("div", {
                          key: 0,
                          class: "text",
                          innerText: 
                userinfo.value.teamLevel == 1
                  ? _unref(one)
                  : userinfo.value.teamLevel == 2
                    ? _unref(two)
                    : userinfo.value.teamLevel == 3
                      ? _unref(Samsung)
                      : userinfo.value.teamLevel == 4
                        ? _unref(four)
                        : _unref(five)
              
                        }, null, 8, _hoisted_13))
                      : _createCommentVNode("", true),
                    _createElementVNode("div", {
                      class: "time_",
                      style: _normalizeStyle(
                userinfo.value.teamLevel == 0
                  ? 'margin-top: 0.1rem'
                  : 'margin-top: 0rem'
              )
                    }, _toDisplayString(_unref(lang).v.Expiration) + " " + _toDisplayString(userinfo.value.talentCardExpiredTime), 5)
                  ]))
                : _createCommentVNode("", true),
              _createElementVNode("div", {
                class: "renew",
                onClick: renew
              }, _toDisplayString(_unref(lang).v.renew), 1)
            ])
          ], 4),
          _createElementVNode("div", _hoisted_14, [
            _createElementVNode("div", _hoisted_15, [
              _createElementVNode("div", {
                class: "prompt",
                onClick: wallet
              }, [
                _createElementVNode("div", _hoisted_16, _toDisplayString(_unref(lang).v.balance), 1),
                _cache[0] || (_cache[0] = _createElementVNode("div", { class: "went" }, [
                  _createElementVNode("img", {
                    src: _imports_0,
                    alt: ""
                  })
                ], -1))
              ]),
              _createElementVNode("div", _hoisted_17, _toDisplayString(_unref(lang).v.Income) + " " + _toDisplayString(_unref(lang).v.unit) + " " + _toDisplayString(userinfo.value.totalIncome), 1)
            ]),
            _createElementVNode("div", _hoisted_18, [
              _createElementVNode("div", _hoisted_19, [
                _createElementVNode("span", _hoisted_20, _toDisplayString(_unref(lang).v.unit), 1),
                _createElementVNode("span", _hoisted_21, _toDisplayString(userinfo.value.talentBalance), 1)
              ]),
              (zhuanru.value)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: "go_wallet",
                    onClick: tixian
                  }, _toDisplayString(_unref(lang).v.wallet), 1))
                : (_openBlock(), _createElementBlock("div", _hoisted_22, [
                    _createVNode(_component_van_loading, {
                      type: "spinner",
                      size: "0.24rem",
                      color: "#FFFFFF"
                    })
                  ]))
            ])
          ]),
          _createElementVNode("div", _hoisted_23, [
            _createElementVNode("div", {
              class: "own_box",
              onClick: daily
            }, [
              _cache[2] || (_cache[2] = _createElementVNode("div", { class: "box_img" }, [
                _createElementVNode("img", {
                  src: _imports_1,
                  alt: ""
                })
              ], -1)),
              _createElementVNode("div", _hoisted_24, [
                _createElementVNode("div", _hoisted_25, [
                  _createElementVNode("div", _hoisted_26, _toDisplayString(_unref(lang).v.daily), 1),
                  _cache[1] || (_cache[1] = _createElementVNode("div", { class: "went" }, [
                    _createElementVNode("img", {
                      src: _imports_0,
                      alt: ""
                    })
                  ], -1))
                ]),
                _createElementVNode("div", _hoisted_27, _toDisplayString(_unref(lang).v.nocompleted), 1),
                _createElementVNode("div", _hoisted_28, _toDisplayString(userinfo.value.taskNum), 1)
              ])
            ]),
            _createElementVNode("div", {
              class: "own_box",
              onClick: code_vip
            }, [
              _cache[4] || (_cache[4] = _createElementVNode("div", { class: "box_img" }, [
                _createElementVNode("img", {
                  src: _imports_2,
                  alt: ""
                })
              ], -1)),
              _createElementVNode("div", _hoisted_29, [
                _createElementVNode("div", _hoisted_30, [
                  _createElementVNode("div", _hoisted_31, _toDisplayString(_unref(lang).v.code), 1),
                  _cache[3] || (_cache[3] = _createElementVNode("div", { class: "went" }, [
                    _createElementVNode("img", {
                      src: _imports_0,
                      alt: ""
                    })
                  ], -1))
                ]),
                _createElementVNode("div", _hoisted_32, _toDisplayString(_unref(lang).v.Residue), 1),
                _createElementVNode("div", _hoisted_33, _toDisplayString(userinfo.value.codeNum), 1)
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_34, [
            _createElementVNode("div", _hoisted_35, [
              _createElementVNode("div", _hoisted_36, [
                _cache[5] || (_cache[5] = _createElementVNode("div", { class: "reward_logo" }, [
                  _createElementVNode("img", {
                    src: _imports_3,
                    alt: ""
                  })
                ], -1)),
                _createElementVNode("div", _hoisted_37, _toDisplayString(_unref(lang).v.reward) + " " + _toDisplayString(_unref(lang).v.unit) + _toDisplayString(userinfo.value.todayReward), 1)
              ]),
              _createElementVNode("div", {
                class: "refresh",
                onClick: refresh
              }, [
                _createElementVNode("img", {
                  style: _normalizeStyle(imageStyle.value),
                  src: _imports_4,
                  alt: "",
                  class: "rotateImg"
                }, null, 4)
              ])
            ]),
            _createElementVNode("div", _hoisted_38, [
              _createElementVNode("div", _hoisted_39, [
                _createVNode(_component_el_table, {
                  data: lists.value.filter(v => getIncomeTypeName(v) != ''),
                  style: {"width":"100%"}
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_table_column, {
                      width: "180",
                      label: _unref(lang).v.type,
                      align: "center"
                    }, {
                      default: _withCtx((scope) => [
                        _createElementVNode("div", null, _toDisplayString(getIncomeTypeName(scope.row)), 1)
                      ]),
                      _: 1
                    }, 8, ["label"]),
                    _createVNode(_component_el_table_column, {
                      prop: "amount",
                      align: "center",
                      label: _unref(lang).v.amount
                    }, null, 8, ["label"]),
                    _createVNode(_component_el_table_column, {
                      prop: "time",
                      align: "center",
                      label: _unref(lang).v.time
                    }, null, 8, ["label"])
                  ]),
                  _: 1
                }, 8, ["data"]),
                (loading.value)
                  ? (_openBlock(), _createBlock(_component_van_loading, {
                      key: 0,
                      size: "0.24rem"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_unref(lang).v.load), 1)
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                (more.value)
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 1,
                      class: "list_more",
                      onClick: loadMore
                    }, [
                      _createElementVNode("div", _hoisted_40, _toDisplayString(_unref(lang).v.loadmore), 1),
                      _cache[6] || (_cache[6] = _createElementVNode("div", { class: "more_logo" }, [
                        _createElementVNode("img", {
                          src: _imports_5,
                          alt: ""
                        })
                      ], -1))
                    ]))
                  : _createCommentVNode("", true)
              ])
            ])
          ])
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_41, [
          _createElementVNode("div", {
            class: "info_senior",
            style: _normalizeStyle({
          background:
            timeDifferenceInMilliseconds.value <= 0 ||
            userinfo.value.talentCardExpiredTime == null
              ? 'url(' + imgs.img3 + ')'
              : 'url(' + imgs.img1 + ')',
        })
          }, [
            _createElementVNode("div", _hoisted_42, [
              _createElementVNode("div", _hoisted_43, [
                _createElementVNode("div", _hoisted_44, [
                  _createElementVNode("img", {
                    src: userinfo.value.avatar,
                    alt: ""
                  }, null, 8, _hoisted_45)
                ]),
                _createElementVNode("div", _hoisted_46, [
                  _createElementVNode("div", _hoisted_47, _toDisplayString(userinfo.value.nickname), 1),
                  _createElementVNode("div", _hoisted_48, "UID: " + _toDisplayString(userinfo.value.userId), 1)
                ])
              ]),
              (
              timeDifferenceInMilliseconds.value <= 0 ||
                userinfo.value.talentCardExpiredTime == null
                ? false
                : true
            )
                ? (_openBlock(), _createElementBlock("div", _hoisted_49, _toDisplayString(_unref(lang).v.Premier), 1))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", _hoisted_50, [
              (
              timeDifferenceInMilliseconds.value <= 0 ||
                userinfo.value.talentCardExpiredTime == null
                ? false
                : true
            )
                ? (_openBlock(), _createElementBlock("div", _hoisted_51, [
                    (userinfo.value.teamLevel == 0 ? false : true)
                      ? (_openBlock(), _createElementBlock("div", {
                          key: 0,
                          class: "text",
                          innerText: 
                userinfo.value.teamLevel == 1
                  ? _unref(one)
                  : userinfo.value.teamLevel == 2
                    ? _unref(two)
                    : userinfo.value.teamLevel == 3
                      ? _unref(Samsung)
                      : userinfo.value.teamLevel == 4
                        ? _unref(four)
                        : _unref(five)
              
                        }, null, 8, _hoisted_52))
                      : _createCommentVNode("", true),
                    _createElementVNode("div", {
                      class: "time_",
                      style: _normalizeStyle(
                userinfo.value.teamLevel == 0
                  ? 'margin-top: 0.1rem'
                  : 'margin-top: 0rem'
              )
                    }, _toDisplayString(_unref(lang).v.Expiration) + " " + _toDisplayString(userinfo.value.talentCardExpiredTime), 5)
                  ]))
                : _createCommentVNode("", true),
              _createElementVNode("div", {
                class: "renew",
                onClick: renew
              }, _toDisplayString(_unref(lang).v.renew), 1)
            ])
          ], 4),
          _createElementVNode("div", _hoisted_53, [
            _createElementVNode("div", _hoisted_54, [
              _createElementVNode("div", {
                class: "prompt",
                onClick: wallet
              }, [
                _createElementVNode("div", _hoisted_55, _toDisplayString(_unref(lang).v.balance), 1),
                _cache[7] || (_cache[7] = _createElementVNode("div", { class: "went" }, [
                  _createElementVNode("img", {
                    src: _imports_6,
                    alt: ""
                  })
                ], -1))
              ]),
              _createElementVNode("div", _hoisted_56, _toDisplayString(_unref(lang).v.Income) + " " + _toDisplayString(_unref(lang).v.unit) + _toDisplayString(userinfo.value.totalIncome), 1)
            ]),
            _createElementVNode("div", _hoisted_57, [
              _createElementVNode("div", _hoisted_58, [
                _createElementVNode("span", _hoisted_59, _toDisplayString(_unref(lang).v.unit), 1),
                _createElementVNode("span", _hoisted_60, _toDisplayString(userinfo.value.talentBalance), 1)
              ]),
              (zhuanru.value)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: "go_wallet",
                    onClick: tixian
                  }, _toDisplayString(_unref(lang).v.wallet), 1))
                : (_openBlock(), _createElementBlock("div", _hoisted_61, [
                    _createVNode(_component_van_loading, {
                      type: "spinner",
                      size: "0.24rem",
                      color: "#FFFFFF"
                    })
                  ]))
            ])
          ]),
          _createElementVNode("div", _hoisted_62, [
            _createElementVNode("div", {
              class: "own_box",
              onClick: daily
            }, [
              _cache[9] || (_cache[9] = _createElementVNode("div", { class: "box_img" }, [
                _createElementVNode("img", {
                  src: _imports_7,
                  alt: ""
                })
              ], -1)),
              _createElementVNode("div", _hoisted_63, [
                _createElementVNode("div", _hoisted_64, [
                  _createElementVNode("div", _hoisted_65, _toDisplayString(_unref(lang).v.daily), 1),
                  _cache[8] || (_cache[8] = _createElementVNode("div", { class: "went" }, [
                    _createElementVNode("img", {
                      src: _imports_6,
                      alt: ""
                    })
                  ], -1))
                ]),
                _createElementVNode("div", _hoisted_66, _toDisplayString(_unref(lang).v.nocompleted), 1),
                _createElementVNode("div", _hoisted_67, _toDisplayString(userinfo.value.taskNum), 1)
              ])
            ]),
            _createElementVNode("div", {
              class: "own_box",
              onClick: code_vip
            }, [
              _cache[11] || (_cache[11] = _createElementVNode("div", { class: "box_img" }, [
                _createElementVNode("img", {
                  src: _imports_8,
                  alt: ""
                })
              ], -1)),
              _createElementVNode("div", _hoisted_68, [
                _createElementVNode("div", _hoisted_69, [
                  _createElementVNode("div", _hoisted_70, _toDisplayString(_unref(lang).v.code), 1),
                  _cache[10] || (_cache[10] = _createElementVNode("div", { class: "went" }, [
                    _createElementVNode("img", {
                      src: _imports_6,
                      alt: ""
                    })
                  ], -1))
                ]),
                _createElementVNode("div", _hoisted_71, _toDisplayString(_unref(lang).v.Residue), 1),
                _createElementVNode("div", _hoisted_72, _toDisplayString(userinfo.value.codeNum), 1)
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_73, [
            _createElementVNode("div", _hoisted_74, [
              _createElementVNode("div", _hoisted_75, [
                _cache[12] || (_cache[12] = _createElementVNode("div", { class: "reward_logo" }, [
                  _createElementVNode("img", {
                    src: _imports_9,
                    alt: ""
                  })
                ], -1)),
                _createElementVNode("div", _hoisted_76, _toDisplayString(_unref(lang).v.reward) + _toDisplayString(_unref(lang).v.unit) + _toDisplayString(userinfo.value.todayReward), 1)
              ]),
              _createElementVNode("div", {
                class: "refresh",
                onClick: refresh
              }, [
                _createElementVNode("img", {
                  style: _normalizeStyle(imageStyle.value),
                  src: _imports_10,
                  alt: "",
                  class: "rotateImg"
                }, null, 4)
              ])
            ]),
            _createElementVNode("div", _hoisted_77, [
              _createElementVNode("div", _hoisted_78, [
                _createVNode(_component_el_table, {
                  data: lists.value.filter(v => getIncomeTypeName(v) != ''),
                  style: {"width":"100%"}
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_table_column, {
                      width: "180",
                      label: _unref(lang).v.type,
                      align: "center"
                    }, {
                      default: _withCtx((scope) => [
                        _createElementVNode("div", null, _toDisplayString(getIncomeTypeName(scope.row)), 1)
                      ]),
                      _: 1
                    }, 8, ["label"]),
                    _createVNode(_component_el_table_column, {
                      prop: "amount",
                      align: "center",
                      label: _unref(lang).v.amount
                    }, null, 8, ["label"]),
                    _createVNode(_component_el_table_column, {
                      prop: "time",
                      align: "center",
                      label: _unref(lang).v.time
                    }, null, 8, ["label"])
                  ]),
                  _: 1
                }, 8, ["data"]),
                (loading.value)
                  ? (_openBlock(), _createBlock(_component_van_loading, {
                      key: 0,
                      size: "0.24rem"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_unref(lang).v.load), 1)
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                (more.value)
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 1,
                      class: "list_more",
                      onClick: loadMore
                    }, [
                      _createElementVNode("div", _hoisted_79, _toDisplayString(_unref(lang).v.loadmore), 1),
                      _cache[13] || (_cache[13] = _createElementVNode("div", { class: "more_logo" }, [
                        _createElementVNode("img", {
                          src: _imports_5,
                          alt: ""
                        })
                      ], -1))
                    ]))
                  : _createCommentVNode("", true)
              ])
            ])
          ])
        ])),
    _createVNode(Tabbar)
  ]))
}
}

})